/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* src/App.css */
/* body, html {
  background-color: pink;
  height: 100%;
  font-family: Verdana, Geneva, sans-serif
} */
.connectionStatus {
  padding-top: 20px;
  margin-left: 20px;
  font-size: 1.5em;
}
.subscriber {
  margin-left: 10%;
}
.publisher {
  float: right;
  margin-right: 10%;
}
/* .OTPublisherContainer {
  width: 80vh !important;
  height: 80vh !important;
} */
.OTPublisherContainer {
  width: "20%" !important;
  height: "20vh" !important;
}
.OTSubscriberContainer {
  width: 120vh !important;
  height: 80vh !important;
}
#error {
  color: red;
}

