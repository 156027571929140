.custom-styles {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
}

.custom-styles .ReactInputVerificationCode__item {
    position: relative;
    color: #262626;
    font-weight: 500;
    background-color: #FFFAF5;
    font-size: 19;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: #001011;
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: #ffb74d;
}
